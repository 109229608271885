@import 'fonts';
@import '../../node_modules/@glidejs/glide/dist/css/glide.core.min.css';
@import 'vars';
:root {
    font-size: 16px;
}
body {
    margin: 0;
    padding: 0;
    font-family: $font-monserrat;
    overflow-x: hidden;
}
h1, h2, h3, h4, h5, h6 {
    margin: 0 0 10px;
    font-weight: 600;
	text-transform: uppercase;
}
p {
    font-family: $font-poppins;
    color: $grey;
    font-size: 1.125rem;
    line-height: 1.7;
    font-weight: 400;
    margin: 0;
}
h1 {
    font-size: 2.5rem;
}
h2 {
    font-size: 1.875rem;
    font-weight: 800;
    margin-bottom: 5px;
}
h3 {
    font-size: 1.375rem;
}
h4 {
    font-size: 1.125rem;
}
.h2 {
    text-transform: uppercase;
    color: $dark-black;
}
img {
    max-width: 100%;
}
a {
    text-decoration: none;
    transition: color 0.3s ease;
}
a:hover {
    color: $main-color;
}
label {
    color: $white;
    background-color: #000;
}
.wrapper {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    position: relative;
	z-index: 2;
}
.small {
    display: block;
    font-size: 0.5em;
}
.theme-color {
    color: $main-color;
}
.btn {
    display: inline-block;
    background-color: $main-color;
    border: none;
    border-radius: 5px;
    font-size: 0.875rem;
    line-height: 3.43em;
    padding: 0 3.4em;
    transition: all 0.3s ease 0s;
    color: $white;
    font-weight: 600;
    text-transform: uppercase;
    border: 1px solid transparent;
}
.btn:hover {
    background-color: transparent;
    border-color: $main-color;
    color: $main-color;
    cursor: pointer;
}
.btn--black {
    background-color: #222;
    &:hover {
        border-color: #222;
        color: $white;
    }
}
.section__text {
    font-family: $font-poppins;
    line-height: 1.7;
    color: $grey;
    margin-bottom: 70px;
}
.section__text::after {
    content: '';
    display: block;
    width: 96px;
    height: 2px;
    background-color: $light-grey;
    margin-top: 12px;
}
.visually-hidden {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
}
.grecaptcha-badge {
    display: none !important;
}