@media (max-width: 1201px) {
    .wrapper {
        width: 970px;
    }
}
@media (max-width: 993px) {
    .wrapper {
        width: 750px;
    }
    .banner__caption {
        width: 750px;
    }
    .banner__heading--2 {
        font-size: 3.45rem;
    }
    .main-nav__link {
        font-size: 0.75rem;
    }
    .main-nav__item {
        margin: 0 5px;
    }
    #features {
        margin-top: 20px;
    }
    .feature {
        padding: 3em 2em;
        box-sizing: border-box;
    }
    .course {
        width: 48.5%;
    }
    .resource {
        flex-direction: column;
        padding: 10px 15px;
    }
    .resource__image-link {
        width: 100%;
    }
    .resource__info {
        width: 100%;
        margin-right: 0;
    }
    .resource__heading {
        margin: 15px 0;
    }
    
    .breadcrumbs {
        padding: 70px 0;
    }
    .footer-subscribe-wrapper {
        flex-direction: column;
        padding: 30px 50px;
    }

}
@media (max-width: 769px) {
    .wrapper {
        width: 560px;
    }
    .banner__caption {
        width: 560px;
    }
    .page-header {
        padding: 10px 0;
    }
    .page-header__wrapper {
        flex-wrap: wrap;
        align-items: center;
    }
    .main-nav {
        width: 100%;
    }
    .main-nav__toggle {
        display: block;
    }
    .page-header__logo__img,
    .main-nav__toggle {
        position: relative;
        bottom: -14px;
    }
    .main-nav__list::before {
        content: '';
        width: 100%;
        height: 1px;
        background: $white-darken;
    }
    
    .main-nav__list {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        max-height: 0;
        transition: max-height 1s linear;
    }
    .main-nav__list--visible {
        max-height: 400px;
    }
    .main-nav__link {
        font-size: 0.875rem;
        line-height: 2.85;
    }
    .banner__text {
        display: none;
    }
    .banner__heading {
        font-size: 1.5rem;
    }
    #features {
        margin-top: 40px;
    }
    .features__wrapper {
        flex-direction: column;
    }
    .feature {
        width: 100%;
    }
    .course {
        width: 100%;
    }
    .about-us-wrapper {
        flex-direction: column;
    }
    .about-us__content, .about-us__form, .about-us__subscribe {
        width: 100%;
    }
    .resources {
        flex-direction: column;
        margin-bottom: 30px;
    }
    .resource {
        width: 100%;   
        padding: 30px 15px;
        &:nth-of-type(odd) {
            margin-right: 0;
        }
    }
    .resource__heading {
        margin: 20px 0;
    }
    .resource__info {
        margin-right: 0;
    }
    
    .footer-subscribe__info {
        width: 100%;
    }
    .footer-subscribe__heading {
        margin-bottom: 20px;
    }
    .contacts-wrapper {
        flex-direction: column;
    }
    #leave-message, #get-in-touch {
        width: 100%;
    }
    .breadcrumbs {
        padding: 40px 0;
    }

}
@media (max-width: 577px) {
    .wrapper {
        width: 100%;
        box-sizing: border-box;
        padding: 0 15px;
    }
    .banner__caption {
        width: 100%;
        margin-top: -75px;
    }
    .btn {
        line-height: 2.5em;
        padding: 0 2em;
    }
  
    .feature {
        padding: 3em 15px;
    }
    .resource {
        flex-direction: column;
    }
    .resource__image {
        width: 100%;
    }
    #slogan {
        padding: 75px 0;
    }
    #reviews {
        padding: 20px 0;
    }
    .footer-subscribe-wrapper {
        padding: 40px 15px;
    }
    .footer-wrapper {
        flex-direction: column;
        align-items: center;
    }
    .contacts-wrapper {
        padding: 40px 0;
    }
}
