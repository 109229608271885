@import 'base';
/*=================
 * PAGE HEADER
===================*/
.page-header {
	min-height: 80px;
	display: flex;
	align-items: center;
	position: fixed;
	position: sticky;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 100;
	background: $white;
}
.page-header__logo {
	width: 180px;
}
.page-header__wrapper {
	justify-content: space-between;
}
// Navigation
.main-nav__list {
	display: flex;
	padding: 0;
	list-style: none;
}
.main-nav__toggle {
	display: none;
	height: 50px;
    width: 50px;
	background: none;
	border: 1px solid #ccc;
	border-radius: 3px;
	font-size: 0;
	cursor: pointer;
}
.menu-touch {
	width: 100%;
	height: 100%;
	height: 100%;
}
.main-nav__item {
	margin: 0 10px;
}
.main-nav__link {
	font-size: 0.875rem;
	font-weight: 600;
    display: block;
    line-height: inherit;
    padding: 0 2px;
    position: relative;
    color: $black;
    text-transform: uppercase;
}

/*=================
 * BANNER
 ===================*/
.rt-effect{
	transition-duration: 1s;
	transition-delay: 0s;
	opacity: 0;
	position: relative;
}
.banner {
	position: relative;
	overflow: hidden;

	&-wrapper {
		position: static;
	}
	&__caption {
		position: absolute; 
		top: 50%;
		margin-top: -130px;
		width: 900px;

		overflow: hidden;
		visibility: hidden;
	}
	&__caption--active {
		visibility: visible;
	}
	&__heading {
		margin: 0;
		color: $white;
		opacity: 0;
	}

	&__caption--active .rt-effect{
		opacity: 1;
	}
	&__heading--1 {
		font-size: 1.875rem;
		top: -35px;
	}
	&__caption--active .banner__heading--1 {
		top: 0;
		transition-delay: .6s;
	}

	&__heading--2 {
		font-size: 3.75rem;
		font-weight: 800;
		height: 0;
		overflow: hidden;
		transition: height 1.5s;
		opacity: 1;
	}
	&__caption--active .banner__heading--2 {
		height: 75px;
		transition-delay: 1.2s;
	}
	&__text {
		font-size: 1.125rem;
		line-height: 1.7;
		font-weight: 300;
		color: $white-darken;
		margin-bottom: 25px;
	}
	&__caption--active  .banner__text {
		transition-delay: 2s;	
	}
	&__btn {
		bottom: -52px;
		line-height: 50px;
		height: 50px;
		&:hover {
			color: $white;
		}
	}
	&__caption--active .bunner__btn {
		bottom: 0;
		transition-delay: 2.4s;
	}
	&__btn:hover {
		color: $white;
	}
}
/*=================
 * FEATURES
===================*/
#features {
	margin-top: -80px;
}
.features__wrapper {
	display: flex;
}
.feature {
	width: 33.33%;
	flex-grow: 1;
	font-size: 1.125rem;
	color: $white;
	padding: 4em 2em;
	position: relative;
	background-color: $yellow;
	&__icon {
		position: absolute;
		right: 10px;
		bottom: 10px;
		z-index: 1;
		opacity: 0.06;
		transition: 0.25s all;
	}
	&:hover &__icon {
		opacity: 0.1;
    	right: 20px;
	}
	&--2 {
		background-color: $main-color;
		box-shadow: 0 0 20px 0 rgba(5, 5, 6, 0.3);
    	z-index: 2;
	}
	&__heading {
		margin-bottom: 1em;
	}
	&__text {
		line-height: 1.6;
	}
}
	
	/*=================
 * COURSES
===================*/
#courses {
	padding: 40px 0;
}
.courses-wrapper {
	flex-direction: column;
}
.courses__list {
	display: flex;
	flex-wrap: wrap;
}
.course {
	width: 30%;
	margin-right: 1.5%;
	margin-bottom: 15px;
	box-sizing: border-box;
	border: 1px solid $white-darken;
	padding: 22px 25px 30px;
	transition: 0.25s border-color;

	&:hover {
		border-color: #169eb7;
	}
	&:nth-of-type(3n) {
		margin-right: 0;
	}
	&__duration {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: $main-color;
		border-radius: 50%;
		box-shadow: 0 0 8px 0 rgba(5, 5, 6, 0.4);
		width: 60px;
		height: 60px;
		font-size: 1.375rem;
		font-weight: 700;
		line-height: 1.1;
		color: $white;
		position: relative;
		top: -32px;
		margin-bottom: -15px;
	}
	&__hours {
		font-size: 0.875rem;
		font-weight: 400;
	}
	&__name {
		font-size: 1.25rem;
		color: $dark-grey;
	}
	&__rating {
		margin-bottom: 5px;
	}
	&__rating__star {
		color: #f3c30b;
		font-size: 16px;
		margin: 0 1px;
	}
	&__description {
		margin-bottom: 20px;
	}
}
/*=================
 * ABOUT
===================*/
#about-us {
	position: relative;
	background-image: url(../images/fly.jpg);
	background-attachment: fixed;
	background-size: cover;
	background-position: center;
	padding: 70px 0;
	color: $white;
	
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(22,158,183,.85);
	}
}
.about-us {
	&__content {
		width: 50%;
		margin-bottom: 20px;
	}
	&__heading {
		color: inherit;
		font-size: 2rem;
		margin-bottom: 30px;
	}
	&__small {
		display: block;
		font-weight: 500;
		font-size: 0.6875em;
	}
	&__text {
		color: inherit;
		// font-size: 1em;
	}
	&__form {
		width: 50%;
	}
	&__subscribe {
		display: flex;
		flex-direction: column;
		width: 320px;
		margin: 0 auto;
		padding: 50px 35px;
		box-sizing: border-box;
		background: $white;
		color: $semi-grey;
	}
	&__form__heading {
		font-size: 1.2rem;
		margin-bottom: 20px;
	}
}
.form__input {
	border: 1px solid $white-darken;
    color: $grey;
	font-family: $font-monserrat;
	font-weight: 600;
    line-height: 2.9;
    margin-bottom: 15px;
	padding: 0 15px;
    box-sizing: border-box;
    width: 100%;
}
.form__input::placeholder {
	color: $white-grey;
}
.alert {
	box-sizing: border-box;
    margin: 1rem 0 -1rem;
	border-radius: .25rem;
	max-height: 0;
	overflow: hidden;
	transition: all 0.25s;
	padding: 0 1.25rem;
	&.show {
		padding: .75rem 1.25rem;
		max-height: 100px;
	}
}
.error {
	color: #721c24;
	background-color: #f8d7da;
	&.show{
		border: 1px solid #f5c6cb;
	}
}
.success {
	color: #155724;
	background-color: #d4edda;
	&.show {
		border: 1px solid #c3e6cb;
	}
}
/*=================
 * RESOURCES
===================*/
#resources {
	padding: 40px 0;
}
.resources-wrapper {
	flex-direction: column;
}
.resources {
	display: flex;
	margin-bottom: 60px;
}
.resources__btn {
	align-self: center;
}
.resource {
	width: calc(50% - 15px);
	margin-bottom: 20px;
	display: flex;
	align-items: flex-start;
	overflow: hidden;
	padding: 10px 30px 10px 10px;
	box-sizing: border-box;
	box-shadow: 0px 0px 5px 0px rgba(9, 9, 9, 0.1);
	transition: box-shadow 0.5s;

	&:hover {
		box-shadow: 0px 0px 5px 0px $main-color;
	}
	
	&:nth-of-type(odd){
		margin-right: 30px;
	}
	&__info {
		width: 60%;
		margin-right: 15px;
	}
	&__image {
		width: 100%;
		border-radius: 5px;
		object-fit: contain;
	}
	&__link {
		color: $black;
	}
	&__image-link {
		min-width: 40%;
		width: 40%;
		margin-right: 10px;
	}
	&__text {
		margin-bottom: 10px;
	}
}
/*=================
 * SLOGAN
===================*/
#slogan {
	padding: 150px 0;
	position: relative;
	background: url(../images/about-us.jpg);
	background-size: cover;
	background-attachment: fixed;

	&::before {
		content: '';
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: rgba(156, 39, 176, 0.41);
	}
}
.slogan__heading {
	color: $white;
	font-weight: 800;
}
.slogan__small {
	font-weight: 600;
	margin-bottom: 20px;
}
/*=================
 * REVIEWS
===================*/
#reviews {
	padding: 40px 0;
	text-align: center;
}
.reviews-wrapper {
	flex-direction: column;
	align-items: center;
}
.reviews__section__text::after {
	margin-left: auto;
	margin-right: auto;
}
.review {
	&__name {
		color: $main-color;
	}
	&__image {
		width: 150px;
		height: 150px;
		margin-bottom: 20px;
		border-radius: 50%;
		object-fit: cover;
	}
	&__text {
		padding: 0 20px;
	}
}

.glide__arrow {
  border: none;
  background: none;
  font-size: 0;
  position: absolute;
  top: 150px;
  &:hover {
	  cursor: pointer;
 	 .svg-line {
		fill: #666;
  	}
  }
	
}
.svg-arrow-icon {
	width: 50px;
	height: 50px;
}
.svg-line {
	fill: #999;
	transition: fill 0.5s;
}
.glide__arrow--left {
  left: 0;
}
.glide__arrow--right {
  right: 0;
}

/*=================
 * SUBSCRIBE
===================*/
.footer-subscribe {
	background: linear-gradient( #ffffff 50%, #1b1b1b 50%);

	&-wrapper {
		background-color: $main-color;
		justify-content: space-between;
		padding: 45px 90px;
		box-sizing: border-box;
		color: $white;
	}
	&__text {
		color: inherit;
	}
	&__info {
		width: 60%;
	}
	&__btn {
		width: 100%;
	}
}
/*=================
 * FOOTER
===================*/
.page-footer {
	background: #1b1b1b;
	padding-top: 40px;
}
.footer-wrapper {
	justify-content: space-between;
	align-items: center;
}
.page-footer__copyright {
	font-weight: 300;
	font-family: $font-monserrat;
	color: $white-darken;
}
.socials {
	display: flex;
	list-style: none;
	padding: 0;
}
.socials__item {
	width: 30px;
	height: 30px;
	margin-left: 10px;
}
.socials__link {
	display: block;
	width: 100%;
	height: 100%;
	font-size: 1.625rem;
	line-height: 2;
	color: $light-grey;
	svg {
		width: 100%;
		height: 100%;
	}
}
.icon__youtube:hover .svg-line {
	fill: #f00;
}
.icon__facebook:hover .svg-line {
	fill: #0879e9;
}
.socials__link:hover {
	color: $main-color;
}
/*=================
 * CONTACTS PAGE
===================*/
.breadcrumbs {
	padding: 150px 0;
	position: relative;
	background-position: center;
	background-size: cover;
	background-attachment: fixed;
	color: $white;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(27,27,27,.80);
	}
	&.breadcrumbs-resources::before {
		background-color: rgba(27,27,27,.4);
	}
	&__picture {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -2;
		overflow: hidden;
	}
	&__image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	&__heading {
		font-weight: 700;
	}
	&-wrapper {
		flex-direction: column;
	}
	&__list {
		list-style: none;
		padding: 0;
		display: flex;
	}
	&__link {
		color: inherit;
		text-transform: uppercase;
		font-size: 0.75rem;
		font-weight: 500;
	}
	&__item:not(:last-of-type)::after {
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: 20px;
		height: 20px;
		margin: 0 15px;
		background-image: url(../images/next.svg);
		background-size: cover;
	}
}
.contacts-wrapper {
	padding: 40px 0;
}
#leave-message {
	width: 60%;
	padding: 0 15px;
	box-sizing: border-box;
	margin-bottom: 40px;
}
#get-in-touch {
	width: 40%;
	padding: 0 15px;
	box-sizing: border-box;
}
.contacts__section__text {
	margin-bottom: 45px;
}
.get-in-touch {
	&__email {
		font-weight: 600;
		margin-bottom: 5px;
		text-transform: uppercase;
		font-size: 1rem;
	}
	&__link {
		display: block;
		color: $main-color;
		font-size: 0.875rem;
	}	
}
/*=================
 * RESOURCES PAGE
===================*/
#resources-page__resources {
	padding: 40px 0;
}
.resources__list {
	flex-wrap: wrap;
}